/**
 * Check if browser is supported.
 * @returns {boolean} true if the browser is supported, false otherwise.
 */
export const isSupportedBrowser = () => {
  const ua = navigator.userAgent.toLowerCase();
  const noneTargets = ['msie', 'trident'];

  // Check non targets browsers
  for (const value of noneTargets) {
    if (ua.includes(value)) {
      return false;
    }
  }

  // Check for mobile version
  const mobileVersionKeywords = ['android', 'mobile'];
  if (mobileVersionKeywords.some((keyword) => ua.includes(keyword))) {
    return true;
  }

  // Initialize map for browser tests (key : browsername, value : Object with regular expression and minimum version)
  const minVersionsMap = new Map([
    ['edge', { expression: /edg\/([\d]+)/, minVersion: 84 }], // First chromium release 07/2020
    ['firefox', { expression: /firefox\/([\d]+)/, minVersion: 68 }], // ESR 07/2020
    ['chrome', { expression: /chrome\/([\d]+)/, minVersion: 80 }], // First 2020 release 02/2020
  ]);

  // Check browsers version
  for (const [browserName, browserTest] of minVersionsMap.entries()) {
    if (ua.includes(browserName)) {
      const m = ua.match(browserTest.expression);
      return m != null && parseFloat(m[1]) >= browserTest.minVersion;
    }
  }

  return false;
};
