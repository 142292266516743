import { LanguageEvents } from '../core/constants/languages';
import { EventBus } from '../core/events';
import { getExtensionPoint } from '../core/extensionPoints';
import { ignoreNavigationDuplicatedErrorHandler } from '../core/router';
import { isSupportedBrowser } from '../utils/browsers';
import { PlatformEvents } from './constants/events';
import { ROUTE_NAME_404 } from './constants/routes';

/**
 * Navigation timeout duration for an added route
 */
const ADDED_ROUTE_NAVIGATION_TIMEOUT = 1000;

const platformExtension = getExtensionPoint('platform');

/**
 * Navigates to application error page
 */
async function goToErrorPage() {
  await platformExtension.router
    .push({ name: 'applicationError' })
    .catch(ignoreNavigationDuplicatedErrorHandler);
}

/**
 * Ends the startup process by setting the modules loading state to false
 */
function endStartup() {
  platformExtension.services.getService('moduleManager').setLoadingModules(false);
  EventBus.$emit(PlatformEvents.APP_STARTED);
}

/**
 * Starts up the platform application
 */
async function start() {
  let versionUpgraded = false;

  try {
    EventBus.$emit(PlatformEvents.APP_STARTING);
    platformExtension.services.getService('moduleManager').setLoadingModules(true);

    // Check if the browser is supported.
    if (!isSupportedBrowser()) {
      await platformExtension.router.push({ name: 'browserVersionError' });
      return;
    }

    // Init languages on app start
    const appInfo = await platformExtension.services.getService('appInfo').loadAppInfo();
    versionUpgraded = appInfo.versionUpgraded;

    await platformExtension.services.getService('languages').initializeLanguages(appInfo.languages);
    platformExtension.services.getService('languages').updatePageLocale();
    EventBus.$on(
      LanguageEvents.LOCALE_CHANGE,
      platformExtension.services.getService('languages').updatePageLocale
    );

    // Load all extension modules
    if (versionUpgraded) {
      platformExtension.services.getService('applications').clearCache();
    }
    await platformExtension.services.getService('moduleManager').loadAll(!versionUpgraded);
  } catch (err) {
    // After app startup error, redirect to app error page
    console.error('Error on app startup', err);

    await goToErrorPage();
    endStartup();
    return;
  }

  try {
    // After app startup success, we want to remove global loading state. BUT if the current
    // route name is 404 AND the url is resolved to a different route, that means that
    // the current route is from an added module and navigation has not finished
    // yet. To prevent flashing the 404 page, wait for navigation to finish and only then
    // toggle the modules loading state

    const resolved = platformExtension.router.resolve(platformExtension.router.currentRoute.path);
    const current = platformExtension.router.currentRoute;
    if (
      resolved &&
      resolved.route.name !== ROUTE_NAME_404 &&
      current &&
      current.name === ROUTE_NAME_404
    ) {
      let unregisterRouterHook = null;
      let timeout = null;
      try {
        await new Promise((resolve) => {
          // Prevent waiting for navigation for too long in case of unexpected error. The 404 page
          // might still be shown, but it's better than waiting indefinitely.
          timeout = setTimeout(resolve, ADDED_ROUTE_NAVIGATION_TIMEOUT);

          unregisterRouterHook = platformExtension.router.afterEach((to, from) => {
            if (from.name === ROUTE_NAME_404 && to.name === resolved.route.name) {
              resolve();
            }
          });
        });
      } finally {
        unregisterRouterHook && unregisterRouterHook();
        clearTimeout(timeout);
      }
    }
  } catch (err) {
    console.error('Error on app startup', err);
    await goToErrorPage();
  }

  endStartup();
}

start();
